.Element-Container {
    width: 100%;
    margin-top: -10px;
}

.Text-Container {
    width: 98%;
    margin: 5px;
}

.Text-Title {
    font-size: 16pt;
    font-weight: bold;
}

.Text-Heading {
    font-size: 12pt;
    font-weight: bold;
}

.Row-Container {
    width: calc(100% - 50px);
    margin-left: 15px;
    display: inline-block;
}

.Row-Item {
    float: left;
}

.TB-Container {
    display: inline-block;
    margin-right: 5px;
}

.TB-Label {
    font-size: 11pt;
}

.TB-Input {
    border: none;
    border-bottom: 1pt solid #000;
    padding: 1px
}

.Sign-Container {
    display: inline-block;
}

.Sign-Label {
    font-size: 11pt;
    margin: 5px;
    float: left;
    vertical-align: bottom;
    font-style: italic;
    font-weight: bold;
}

.Sign-Value {
    height: 26px;
    float: left;
    border-bottom: 1pt solid #000;
    width: 150px;
}

.Sign-Value img {
    height: 36px;
    max-width: 150px;
}

.Initial-Value {
    height: 26px;
    float: left;
    border-bottom: 1pt solid #000;
    width: 50px;
}

.Initial-Value img {
    height: 26px;
    max-width: 50px;
}

.CB-Container {
    display: block;
    position: relative;
    width: 100%;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    border-radius: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.CB-Container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.CB-Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    transition: .25s background-color ease;
}
  
.CB-Container:hover input ~ .CB-Checkmark {
    background-color: #ccc;
}
  
.CB-Container input:checked ~ .CB-Checkmark {
    background-color: #2196F3;
}
  
.CB-Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.CB-Container input:checked ~ .CB-Checkmark:after {
    display: block;
}
  
.CB-Container .CB-Checkmark:after {
    left: 9px;
    top: 0px;
    width: 10px;
    height: 20px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.CB-PDF-Container {
    display: inline-block;
}

.CB-PDF-Main-Label {
    font-size: 11pt;
    margin-right: 10px;
    font-weight: bold;
    float: left;
}
.CB-PDF-Label {
    font-size: 11pt;
    margin-right: 15px;
    margin-left: 5px;
    float: left;
}
.CB-PDF-Input {
    font-size: 11pt;
    margin-right: 10px;
    float: left;
}

.Multi-PDF-Container {
    display: inline-block;
}

.Multi-PDF-Item {
    float: left
}

.Table-Row {
    width: 100%;
    display: inline-block;
}

.Table-Cell {
    float: left;
    border: 1pt solid #000;
}

.Table-Cell-Heading {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 11pt;
}

.HTML-Container{
    width: 98%;
    margin: 5px;
}

.Pretty-CB-Container {
    height: 100px;
    width: 100%;
    vertical-align: middle;
    text-align: left;
    padding: 10px;
    background-color: #595959;
    border-radius: 5px;
    color: #fff;
}

.Pretty-CB-Container span {
    font-size: 18pt;
    margin-left: 10px;
}

.Pretty-Row-Container {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-bottom: 10px;
    display: inline-block;
}

.Pretty-CBG-Label {
    width: calc(100% - 40px);
    display: inline-block;
    text-decoration: underline;
    margin-bottom: 10px;
}

.Pretty-Sign-Label {
    font-size: 18pt;
    margin: 5px;
    float: left;
    vertical-align: bottom;
    font-style: italic;
    font-weight: bold;
}

.Pretty-Sign-Value {
    height: 38px;
    float: left;
    border-bottom: 1pt solid #000;
    width: 250px;
    text-shadow: 0 0 10px #ffff00;
}

.Pretty-Sign-Value img {
    height: 50px;
    max-width: 250px;
}

.Pretty-Initial-Value {
    height: 38px;
    float: left;
    border-bottom: 1pt solid #000;
    width: 50px;
    text-shadow: 0 0 10px #ffff00;
}

.Pretty-Initial-Value img {
    height: 40px;
    max-width: 50px;
}

.Pretty-Signature {
    z-index: 2;
    position: absolute;
    margin: 0 auto;
    transition: .25s all ease;
}

.Pretty-Signature-Start {
    bottom: 70px;
    right: 0;
    height: 0;
    width: 0;
    opacity: 0;
}

.Pretty-Signature-End {
    bottom: 60vh;
    height: 0;
    width: 800px;
    opacity: 1;
    right: calc(50% - 400px);
}

.Pretty-Signature-Button {
    width: 815px;
    height: 50px;
    font-weight: bold;
    font-size: 18pt;
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
    float: left;
    padding-top: 5px;
    cursor: pointer;
    transition: .25s all ease;
}

.Pretty-Signature-Button:hover {
    box-shadow: 0 0 15px #4d4d4d;
    background-color: #4d4d4d;
    color: #fff;
}

.Pretty-Sign-Info {
    position: relative;
    font-size: 8pt;
    top: 0%;
}

