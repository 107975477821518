.Home {
    padding: 60px 0;
    background: linear-gradient(to right, #3771be 0%, #41c2e9 100%);
    height: calc(100% - 69px);
    border-radius: 5px;
}

.Home .lander {
    padding: 40px 0;
    text-align: center;
    border-radius: 5px;
}
  
.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
  
.Home .lander p {
    color: #fff;
}

.Home-Img {
  width: 52px;
  margin: 0 auto;
}

.Home-Title {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 48px;
  color: #fff;
}

.Home-Thick {
  font-weight: bold;
  padding: 10px;
}

.Home-Thin {
  padding: 10px;
}

.Home-Cards-Row {
  width: 100%;
  margin-top: 50px;
}

.Home-Cards-Container {
  position: relative;
  width: 930px;
  margin: 0 auto;
}

.Home-Card-Container {
  width: 450px;
  height: 220px;
  background-color: #fff;
  border-radius: 10px;
  float: left;
  transition: .25s all ease;
}

.Home-Card-Container:hover {
  box-shadow: 0 0 10px #000;
}

.Home-Card-Inner {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.Home-Card-Left {
  width: 185px;
  height: 100%;
  text-align: center;
  float: left;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Home-Card-Left img {
  width: 100px;
  margin: 30px auto;
}

.Home-Card-Right {
  width: 265px;
  height: 100%;
  text-align: center;
  float: left;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #495E8B;
  padding-right: 10px;
}

.Home-Card-Title {
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  text-align: left;
  font-size: 24px;
}

.Home-Card-Details {
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  text-align: left;
}

.Home-Card-Arrow {
  position: relative;
  top: 190px;
  width: 100%;
  text-align: right;
}
  