.Outter-Container {
    height: 100%;
    max-height: calc(100vh - 180px);
    width: 100%;
    overflow-y: auto;
}

.Form-Container {
    position: relative;
    width: 220mm;
    margin: 0 auto;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 15px #8a8787;
    padding-top: 30px;
    padding-bottom: 30px;
}

.Form-Signature-Container {
    position: relative;
    width: 100%;
}

.Form-Signature {
    width: 520px;
    position: relative;
    margin: 30px auto;
}

.PDF-Button-Container {
    width: 100%;
    margin: 10px;
    text-align: center;
}

.PDF-Button {
    position: relative;
    margin: 0 auto;
    width: 175px;
    background-color: #fff;
    color: green;
    cursor: pointer;
    transition: all .25s ease;
    font-weight: bold;
    padding: 5px;
    border: 1pt solid green;
    border-radius: 5px;
}

.PDF-Button:hover {
    background-color: green;
    color: #fff;

}

.Pretty-Container {
    position: relative;
    width: calc(100% - 0px);
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 15px #8a8787;
}

.Pretty-Header {
    height: auto;
    background-color: none;
}

.Pretty-Header-Label {
    font-size: 18pt;
    width: 100%;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    color: #000;
}

.Tracker-Container {
    width: 100%;
    padding: 10px;
}

.Pretty-Form-Container {
    width: 90%;
    position: relative;
    margin: 0 auto;
    height: auto;
    min-height: 660px;
    max-height: calc(100vh - 400px);
    overflow: auto;
    font-size: 18pt;
}

.Pretty-Footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 30px);
    background-color: #fff;
    display: inline-block;
}

.Pretty-Button {
    width: 150px;
    height: 50px;
    font-weight: bold;
    font-size: 18pt;
    border-radius: 5px;
    text-align: center;
    margin: 10px;
    float: left;
    padding-top: 5px;
    cursor: pointer;
    transition: .25s all ease;
}

.Pretty-Left {
    float: left;
}

.Pretty-Right {
    float: right;
}

.Pretty-Back {
    border: 1px solid #808080;
    color: #808080;
}

.Pretty-Next {
    border: 1px solid #808080;
    color: #808080;
}

.Pretty-Quit {
    border: 1px solid #4d4d4d;
    color: #4d4d4d;
}

.Pretty-Next {
    border: 1px solid #008800;
    color: #008800;
}

.Pretty-Next:hover {
    box-shadow: 0 0 15px #008800;
    background-color: #008800;
    color: #fff;
}

.Pretty-Quit:hover {
    box-shadow: 0 0 15px #4d4d4d;
    background-color: #4d4d4d;
    color: #fff;
}

.Pretty-Back:hover {
    box-shadow: 0 0 15px #808080;
    background-color: #808080;
    color: #fff;
}


