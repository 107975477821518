.App {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.App-Faded {
  opacity: 0;
}

.App-Showing {
  opacity: 1;
}

.App .navbar-brand {
  font-weight: bold;
}

.App-Container {
  width: 100%;
  height: 100vh;
}

.App-Loading {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, #3771be 0%, #41c2e9 100%);
}

.App-Loading-Bar {
  position: relative;
  margin-top: 50%;
  background-color: #fff;
  transition: .20s all ease-in
}

.App-Loading-Bar-Init {
  height: 5px;
  width: 0;
}

.App-Loading-Bar-Final {
  height: 5px;
  width: 100%;
}

.App-Loading-Bar-Post {
  margin-top: 0;
  width: 100%;
  height: 100vh;
}
