.Signature {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 15px #ccc;
    border: 2px solid #000;
    padding: 5px;
}

.Signature-Active {
    border: 2px solid #ff0000;
    box-shadow: 0 0 15px #ff0000;
}

.Signature-Row {
    width: 100%;
    display: inline-block;
}

.Signature-Button {
    width: 75px;
    border: 1pt solid #000;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: bold;
    float: left;
}

.Signature-Label {
    font-weight: bold;
    font-size: 18pt;
    margin-right: 10px;
    float: left;
    height: 50px;
    padding-top: 10px;
}

.Signature-Image {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: #fff;
    border: 2px solid #000;
    padding: 5px;
    float: right;
    border-radius: 5px;
}

.Signature-Preview-Container {
    float: right;
    bottom: 0;
    margin: 10px;;
}

.Signature-Instructions {
    font-weight: bold;
    font-size: 18pt;
    text-align: center;
}
