@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
      background: linear-gradient(to right, #3771be 0%, #41c2e9 100%);
      height: calc(100% - 69px);
      border-radius: 5px;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
}

.Login-Img {
  width: 52px;
  margin: 0 auto;
}

.Login-Title {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 48px;
  color: #fff;
}

.Login-Thick {
  font-weight: bold;
  padding: 10px;
}

.Login-Thin {
  padding: 10px;
}