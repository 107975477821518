.Dashboard-Container {
    height: calc(100% - 69px);
    width: 100%;
    padding: 15px;
    background-color: #E7F3FC; 
}

.Dashboard-Table {
    margin-top: 20px;
    width: 96%;
    margin-left: 2%;
}

.Dashboard-Header {
    width: 100%;
    height: 39px;
    padding: 5px;
    box-shadow: 0 3px 6px #455A64;
    display: inline-block;
    background-color: #3771BE;
    border-radius: 5px;
}

.Dashboard-Header-Label {
    color: #fff;
    opacity: 9;
    font-weight: bold;
    text-align: center;
    float: left;
    font-size: 13px;
    margin-top: 5px;
}

.Dashboard-Header-Label-Clickable {
    cursor: pointer;
    transition: .25s all ease;
}

.Dashboard-Header-Label-Clickable:hover {
    text-shadow: 0 0 5px #000;
}

.Dashboard-Header-Menu {
    position: absolute;
    top: 150px;
    left: calc(100% - 250px);
    width: 200px;
    border-radius: 5px;
}

.Dashboard-Header-Menu-Item {
    position: relative;
    width: 100%;    
    height: 30px;
    font-size: 12pt;
    text-align: left;
    background-color: #fff;
    border: 1px solid #3771BE;
    color: #3771BE;
    padding: 2px;
    transition: .25s all ease;
    cursor: pointer;
}

.Dashboard-Header-Menu-Item-Ignore {
    position: relative;
    width: 100%;    
    height: 28px;
    font-size: 12pt;
    text-align: left;
    background-color: #3771BE;
    padding: 2px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    color: #fff;
    font-weight: bold;
}

.Dashboard-Header-Menu-Item:hover {
    border: 2px solid #3771BE;
    font-weight: bold;
}

.Dashboard-Header-Menu-Item-Selected {
    border: 2px solid #3771BE;
    font-weight: bold;
    color: rgb(239, 83, 80);
}

.Dashboard-Rows-Container {
    width: 100%;
    max-height: 640px;
    height: auto;
    overflow-y: auto;
}

.Dashboard-Row-Container {
    width: 100%;
    height: 39px;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 5px;
    transition: .25s all ease;
}

.Dashboard-Row-Selected {
    box-shadow: 0 0 5px #000;
}

.Dashboard-Row-Status {
    float: left;
    width: 4px;
    border-radius: 5px;
    height: 35px;
    margin-top: -3px;
}

.Dashboard-Row-Label {
    color: #3771BE;
    font-weight: bold;
    text-align: center;
    float: left;
    font-size: 13px;
    margin-top: 5px;
}

.Dashboard-Row-Selectable {
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;
}

.Dashboard-Row-Selectable:hover {
    text-shadow: 0 0 5px #000;
}

.Dashboard-Expand-Icon {
    transition: .25s all ease;
}

.Dashboard-Expand-Icon:hover {
    text-shadow: 0 0 5px #000;
}

.Dashboard-Details-Container {
    width: 100%;
    height: auto;
    display: inline-block;
    background-color: #fff;
    margin-top: 0px;
    border-radius: 5px;
    padding: 10px;
}

.Dashboard-Details-Text-Container {
    width: auto;
    height: auto;
    float: left;
    margin-left: 15px;
    min-width: 140px;
}

.Dashboard-Details-Text-Title {
    color: #1d4883;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1pt solid #3771be;
    width: 100%;
}

.Dashboard-Details-Text-Subtitle {
    color: #3771be;
    font-size: 15px;
    width: 100%;
    margin-top: 5px;
}

.Dashboard-Details-Text-Value {
    color: #1d4883;
    font-size: 14px;
    width: 100%;
}

.Dashboard-Details-Status-Container {
    width: 100%;
    display: inline-block;
}

.Dashboard-Details-Status-Text {
    float: left;
    color: #3771be;
    font-size: 15px;
    margin-top: 5px;
}

.Dashboard-Details-Status {
    float: right;
    margin-top: 5px;
    margin-right: 15px;
    height: 15px;
    width: 15px;
    padding: 5px;
    border-radius: 50%;
}

.Dashboard-Details-Graph-Container-1 {
    width: 220px;
    height: 220px;
    float: left;
    margin-left: 15px;
    background-color: #e8f1fc;
    border-radius: 5px;
}

.Dashboard-Details-Graph-Container-2 {
    width: 220px;
    height: 220px;
    float: left;
    margin-left: 15px;
    background-color: #d1e3fa;
    border-radius: 5px;
}

.Dashboard-Details-Graph-Container-3 {
    width: 220px;
    height: 220px;
    float: left;
    margin-left: 15px;
    background-color: #bbd5f7;
    border-radius: 5px;
}

.Dashboard-Details-Graph-Title {
    width: 100%;
    height: 45px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
    padding: 5px;
    color: #fff;
    background-color: #3771be;
    display: inline-block;
}

.Dashboard-Details-Graph-Title-Left {
    float: left;
    margin-right: 5px;
    margin-top: 5px;
}

.Dashboard-Details-Graph-Title-Right {
    float: right;
    margin-right: 5px;
    margin-top: 5px;
    font-weight: bold;
}

.Dashboard-Card {
    width: 94%;
    height: auto;
    margin-left: 3%;
    margin-top: 20px;
    display: inline-block;
}

.Dashboard-Card-Row {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
}

.Dashboard-Card-Row-Right {
    width: 100%;
    min-width: 255px;
    display: inline-block;
    margin-top: 30px;
}

.Dashboard-Card-Button {
    width: auto;
    height: 32px;
    padding: 5px;
    text-align: center;
    border: 1px solid #3a75c3;
    border-radius: 5px;
    background-color: #fff;
    color: #3a75c3;
    cursor: pointer;
}

.Dashboard-Card-Button-Left {
    float: left;
}

.Dashboard-Card-Button-Right {
    float: right;
}

.Dashboard-Card-Button:hover {
    box-shadow: 0 0 6px #000;
}

.Dashboard-Card-Text-Title-Med {
    padding-top: 18px;
    color: #1d4883;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1pt solid #3771be;
    width: 100%;
}

.Dashboard-Card-Text-Title-Big {
    color: #1d4883;
    font-size: 32px;
    font-weight: bold;
    border-bottom: 1pt solid #3771be;
    width: 100%;
}

.Dashboard-Card-Text-Subtitle {
    color: #3771be;
    font-size: 15px;
    width: 100%;
    margin-top: 5px;
    font-weight: bold;
}

.Dashboard-Card-Text-Alert {
    color: #3771be;
    font-size: 15px;
    margin-top: 15px;
    font-weight: bold;
    float: left;
}

.Dashboard-Card-Text-Value {
    color: #1d4883;
    font-size: 15px;
    width: 100%;
}

.Dashboard-Card-Initial-Container {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 14px solid #3771be;
    color: #3771be;
    background-color: #fff;
    font-size: 72px;
    text-align: center;
    font-weight: bold;
}

.Dashboard-Card-Initial {
    margin-top: 60px;
}

.Dashboard-Card-Horizontal-Line {
    height: 260px;
    width: 2px;
    border: 1px solid #418ae9;
    float: left;
    margin-left: 15px;
}

.Dashboard-Card-Menu-Container {
    width: auto;
    height: auto;
    float: left;
    margin-left: 15px;
    min-width: 50px;
    transition: .25s all ease;
    cursor: pointer;
}

.Dashboard-Card-Menu-Container:hover{
    text-shadow: 0 0 5px #000;
}

.Dashboard-Card-Menu-Selected {
    border-bottom: 4px solid #82DEFA;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.Dashboard-Card-Section {
    float: left;
    max-width: 75%;
    min-height: 275px;
}

.Dashboard-Card-Section-Left {
    border-right: 2px solid #418ae9;
    padding-right: 10px;
}

.Dashboard-Card-Section-right {
    max-width: 25%;
}

.Dashboard-Card-Row-Height {
    min-height: 275px;
}

.Dashboard-Card-Graph-Container-1 {
    width: 255px;
    height: 250px;
    float: left;
    margin-left: 15px;
    background-color: #eff6fd;
    border-radius: 5px;
}

.Dashboard-Card-Graph-Container-2 {
    width: 255px;
    height: 250px;
    float: left;
    margin-left: 15px;
    background-color: #d1e3fa;
    border-radius: 5px;
}

.Dashboard-Card-Graph-Container-3 {
    width: 255px;
    height: 250px;
    float: left;
    margin-left: 15px;
    background-color: #bbd5f7;
    border-radius: 5px;
}

.Dashboard-Card-Status-Container {
    width: 100%;
    display: inline-block;
}

.Alerts-Updating {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
}

